import Cookies from "js-cookie";

export interface OffersTypes {
  active_from: string;
  active_to: string;
  created_by: number;
  id: number;
  offer_name: string;
  offer_type: string;
  pay_amount: number;
  receive_amount: number;
}

export async function getHardOffers(): Promise<OffersTypes[]> {
  const session_token = Cookies.get("c8_session_token");
  try {
    const res = await fetch(
      `${process.env.API_URL}/offers?offer_type=hard&${session_token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY as string,
        },
      }
    );
    if (res.status !== 200) {
      console.error("Could not fetch offers");
      return [];
    }
    return await res.json();
  } catch (error) {
    return error;
  }
}

export async function getSoftOffers(): Promise<OffersTypes[]> {
  const session_token = Cookies.get("c8_session_token");

  try {
    const res = await fetch(
      `${process.env.API_URL}/offers?offer_type=soft&${session_token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY as string,
        },
      }
    );
    if (res.status !== 200) {
      console.error("Could not fetch offers");
      return [];
    }
    return await res.json();
  } catch (error) {
    return error;
  }
}
